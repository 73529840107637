import React from "react"
import { useTranslation } from "react-i18next"

import './page404.component.less';

import City404 from "../../assets/img/city_404.svg"
import SEO from "../seo";
import {useI18next} from "gatsby-plugin-react-i18next";

const NotFoundPageComponent = () => {
    const { t, i18n } = useTranslation()
    const { originalPath } = useI18next()

    return (
        <div className="wrapper_container_main align_center_ipad wrapper_container_main-flex">
            <SEO description={t("metaDescription")} lang={i18n.language} title="404" link={"https://cityair.io/"+ i18n.language + originalPath} />
            <div className="container">
                <div className="row error_container">
                    <div
                        className="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 col-xga-9 col-fhd-9 col-rt-9 error_col">
                        <div className="error_title">{t("Unfortunately")}</div>
                        <div className="error_subtitle">{t("CheckOut")}</div>
                        <a href="https://cityair.io/">
                            <div className="btn_text">
                                <div className="btn_text__text">{t("HomePage")}</div>
                                <div className="btn_text__shadows"/>
                            </div>
                        </a>
                        <img alt="" src={City404} className="img_city_404-mobile" />
                    </div>
                </div>
            </div>
            <img alt="" src={City404} className="img_city_404" />
        </div>
    )
}

export default NotFoundPageComponent
